import {Component, OnInit} from '@angular/core';
import {AppService} from "@services/app.service";
import {CheckupClienteService} from "@services/dashboard/checkup-cliente/checkup-cliente.service";
import {AnamneseService} from "@services/dashboard/anamnese/anamnese.service";
import {Router} from "@angular/router";
import {ProfessorService} from "@services/dashboard/professor/professor.service";
import { Store } from '@ngrx/store';
import { AppState } from '@/store/state';
import { ToggleSidebarMenu } from '@/store/ui/actions';
import { ToastrService } from 'ngx-toastr';
import { ModalReenviarSenhaComponent } from '@components/dashboard/modais/Modal-ReenviarSenha/modal-reenviarSenha.component';
import { ModalSoliciarCheckupComponent } from '@components/dashboard/modais/Modal-SoliciarCheckup/modal-SoliciarCheckup.component';
import { ShepherdService } from 'angular-shepherd';
import { MenuAvaliadorService } from '@services/dashboard/menu-avaliador/menu-avaliador.service';



const tour = {
   defaultStepOptions: {
    scrollTo: true,
    showCancelLink: true,
    showNavigationArrows: true,
    scrollToHandler: (element: any) => {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });

    },

     cancelIcon: {
      enabled: true
    },
   
    modalOverlayOpeningPadding: 8,
    modalOverlayOpeningRadius: 4
  },
  steps: [
    {
      id: "body-safe",
      attachTo: {
        element: ""
      },
      buttons: [
       
        {
          classes: "shepherd-button-primary-voltar",
          text: "Voltar",
          type: "back"
        },
        {
          classes: "shepherd-button-primary-proximo",
          text: "Próximo",
          type: "next"
        }
      ],
      classes: "shepherd-content",
      title: "Bem vindo ao Tutorial SAFE®",
      text: "Este é o seu Dashboard, aqui você terá acesso a todas as informações sobre seus pacientes.",
    },
    {
      id: "body-safe",
      attachTo: {
        element: ".menu-item-dados"
      },
      buttons: [
       
        {
          classes: "shepherd-button-primary-voltar",
          text: "Voltar",
          type: "back"
        },
        {
          classes: "shepherd-button-primary-proximo",
          text: "Próximo",
          type: "next"
        }
      ],
      classes: "shepherd-content",
      title: "Tutorial SAFE®",
      text: "Nesse menu você pode visualizar os dados dos seus pacientes.",
    },
    {
      id: "body-safe",
      attachTo: {
        element: ".menu-item-clientes"
      },
      buttons: [
       
        {
          classes: "shepherd-button-primary-voltar",
          text: "Voltar",
          type: "back"
        },
        {
          classes: "shepherd-button-primary-proximo",
          text: "Próximo",
          type: "next"
        }
      ],
      classes: "shepherd-content",
      title: "Tutorial SAFE®",
      text: "Nesse menu você pode visualizar os dados dos seus clientes.",
    },
    {
      id: "body-safe",
      attachTo: {
        element: ".menu-item-avaliacaofisica"
      },
      buttons: [
       
        {
          classes: "shepherd-button-primary-voltar",
          text: "Voltar",
          type: "back"
        },
        {
          classes: "shepherd-button-primary-proximo",
          text: "Próximo",
          type: "next"
        }
      ],
      classes: "shepherd-content",
      title: "Tutorial SAFE®",
      text: "Nesse menu você pode Visualizar/Preencher as avaliações físicas dos seus pacientes.",
    },
    {
      id: "body-safe",
      attachTo: {
        element: ".menu-item-avaliacaomente"
      },
      buttons: [
       
        {
          classes: "shepherd-button-primary-voltar",
          text: "Voltar",
          type: "back"
        },
        {
          classes: "shepherd-button-primary-proximo",
          text: "Próximo",
          type: "next"
        }
      ],
      classes: "shepherd-content",
      title: "Tutorial SAFE®",
      text: "Nesse menu você pode Visualizar/Avaliar/Devolver as Anamneses dos seus pacientes.",
    },
    {
      id: "body-safe",
      attachTo: {
        element: ".menu-item-senhacliente"
      },
      buttons: [
       
        {
          classes: "shepherd-button-primary-voltar",
          text: "Voltar",
          type: "back"
        },
        {
          classes: "shepherd-button-primary-proximo",
          text: "Próximo",
          type: "next"
        }
      ],
      classes: "shepherd-content",
      title: "Tutorial SAFE®",
      text: "Nesse menu você pode Visualizar as Senhas provisórias solicitadas pelos seus pacientes.",
    },
    {
      id: "body-safe",
      attachTo: {
        element: ".menu-item-cadastrarnovaavaliacao"
      },
      buttons: [
       
        {
          classes: "shepherd-button-primary-voltar",
          text: "Voltar",
          type: "back"
        },
        {
          classes: "shepherd-button-primary-proximo",
          text: "Próximo",
          type: "next"
        }
      ],
      classes: "shepherd-content",
      title: "Tutorial SAFE®",
      text: "Nesse menu você pode Cadastrar novas avaliações para seus pacientes.",
    },
    {
      id: "body-safe",
      attachTo: {
        element: ".menu-item-relatoriomeuscheckups"
      },
      buttons: [
       
        {
          classes: "shepherd-button-primary-voltar",
          text: "Voltar",
          type: "back"
        },
        {
          classes: "shepherd-button-primary-proximo",
          text: "Próximo",
          type: "next"
        }
      ],
      classes: "shepherd-content",
      title: "Tutorial SAFE®",
      text: "Nesse menu você pode  Visualizar os relatórios dos seus CHECK-UPS.",
    },
     
    {
      id: "body-safe",
      attachTo: {
        element: ".menu-item-relatoriobiomarcadoressafe"
      },
      buttons: [
       
        {
          classes: "shepherd-button-primary-voltar",
          text: "Voltar",
          type: "back"
        },
        {
          classes: "shepherd-button-primary-proximo",
          text: "Próximo",
          type: "next"
        }
      ],
      classes: "shepherd-content",
      title: "Tutorial SAFE®",
      text: "Nesse menu você pode  Visualizar o relatório dos Biomarcadores SAFE®.",
    },
    {
      id: "body-safe",
      attachTo: {
        element: ".menu-item-relatorioprecadastro"
      },
      buttons: [
       
        {
          classes: "shepherd-button-primary-voltar",
          text: "Voltar",
          type: "back"
        },
        {
          classes: "shepherd-button-primary-proximo",
          text: "Próximo",
          type: "next"
        }
      ],
      classes: "shepherd-content",
      title: "Tutorial SAFE®",
      text: "Nesse menu você pode  Visualizar os códigos de pré-cadastro enviados aos seus pacientes.",
    },
    {
      id: "body-safe",
      attachTo: {
        element: ".menu-item-relatoriocheckupssafeporperiodo"
      },
      buttons: [
       
        {
          classes: "shepherd-button-primary-voltar",
          text: "Voltar",
          type: "back"
        },
        {
          classes: "shepherd-button-primary-proximo",
          text: "Próximo",
          type: "next"
        }
      ],
      classes: "shepherd-content",
      title: "Tutorial SAFE®",
      text: "Nesse menu você pode  Visualizar o Relatório de CHECK-UPS SAFE® por Período.",
    },
    {
      id: "body-safe",
      attachTo: {
        element: ".menu-item-relatorioaniversariantemes"
      },
      buttons: [
       
        {
          classes: "shepherd-button-primary-voltar",
          text: "Voltar",
          type: "back"
        },
        {
          classes: "shepherd-button-primary-proximo",
          text: "Próximo",
          type: "next"
        }
      ],
      classes: "shepherd-content",
      title: "Tutorial SAFE®",
      text: "Nesse menu você pode  Visualizar o Relatório de Aniversariantes do Mês.",
    },
    {
      id: "body-safe",
      attachTo: {
        element: ".menu-item-livrosdigitais"
      },
      buttons: [
       
        {
          classes: "shepherd-button-primary-voltar",
          text: "Voltar",
          type: "back"
        },
        {
          classes: "shepherd-button-primary-proximo",
          text: "Próximo",
          type: "next"
        }
      ],
      classes: "shepherd-content",
      title: "Tutorial SAFE®",
      text: "Nesse menu você pode Visualizar os Livros Digitais disponíveis.",
    },
    {
      id: "body-safe",
      attachTo: {
        element: ".menu-item-safeflix"
      },
      buttons: [
       
        {
          classes: "shepherd-button-primary-voltar",
          text: "Voltar",
          type: "back"
        },
        {
          classes: "shepherd-button-primary-proximo",
          text: "Próximo",
          type: "next"
        }
      ],
      classes: "shepherd-content",
      title: "Tutorial SAFE®",
      text: "Nesse menu você pode Acessar o SAFEFLIX, portal de conteúdo exclusivo SAFE®.",
    },
    {
      id: "body-safe",
      attachTo: {
        element: ".menu-item-duvida"
      },
      buttons: [
       
        {
          classes: "shepherd-button-primary-voltar",
          text: "Voltar",
          type: "back"
        },
        {
          classes: "shepherd-button-primary-proximo",
          text: "Próximo",
          type: "next"
        }
      ],
      classes: "shepherd-content",
      title: "Tutorial SAFE®",
      text: "Nesse menu você pode Acessar o nosso canal de dúvidas, com perguntas frequentes.",
    },
    {
      id: "body-safe",
      attachTo: {
        element: ".menu-item-comunidade"
      },
      buttons: [
       
        {
          classes: "shepherd-button-primary-voltar",
          text: "Voltar",
          type: "back"
        },
        {
          classes: "shepherd-button-primary-proximo",
          text: "Próximo",
          type: "next"
        }
      ],
      classes: "shepherd-content",
      title: "Tutorial SAFE®",
      text: "Nesse menu você pode Acessar a nossa comunidade SAFE® no Facebook.",
    },
    {
      id: "body-safe",
      attachTo: {
        element: ".menu-item-suporte"
      },
      buttons: [
       
        {
          classes: "shepherd-button-primary-voltar",
          text: "Voltar",
          type: "back"
        },
        {
          classes: "shepherd-button-primary-proximo",
          text: "Próximo",
          type: "next"
        }
      ],
      classes: "shepherd-content",
      title: "Tutorial SAFE®",
      text: "Nesse menu você pode Acessar o nosso canal de suporte.",
    },
  ]
};

@Component({
  selector: 'app-dashboard-avaliador',
  templateUrl: './dashboard-avaliador.component.html',
  styleUrls: ['./dashboard-avaliador.component.scss']
})
export class DashboardAvaliadorComponent implements OnInit {
  public usuarioLogado = null
  public listaPacientes: any = []
  public listaCheckups: any = []
  public fezCheckup: boolean = false
  public loadingListaPacientes = false;
  public loadingCupom = false;
  public meuLink: string = ""
  public filtroBuscaInput: string = ""
  public buscaFiltrada: any = []
  public laudoSemDados = false
  public urlSafe: string = ""
  public cupom: any = {}
  public isMobile: boolean = false
  public checkupPreencheu: any = {}

  

  copiarMeuLink() {
    /* Get the text field */
    var copyText = document.getElementById("meu-link-avaliador-input");

    /* Select the text field */
    // @ts-ignore
    copyText.select();
    // @ts-ignore
    copyText.setSelectionRange(0, 99999); /* For mobile devices */

    /* Copy the text inside the text field */
    document.execCommand("copy");
  }

    constructor(public appService: AppService,
                private menuService: MenuAvaliadorService,
                private guideTour: ShepherdService,
                private checkupClienteService: CheckupClienteService,
                public anamneseService: AnamneseService,
                private router: Router,
                private professorService: ProfessorService, // Injete o serviço
                private store: Store<AppState>,
                private toastr: ToastrService,
    ) {
    }

  ngOnInit(): void {
    this.usuarioLogado = this.appService.getDadosUsuarioLogadoStorage()
    if (this.usuarioLogado?.ID) {
      this.checkupClienteService.getCheckupCliente(this.usuarioLogado.ID)
      this.gerarLinkAvaliador()
     
    this.professorService.getObterCheckupsCliente(this.usuarioLogado.ID).subscribe(
      (res: any) => {
        this.listaCheckups = res;

        this.fezCheckup = this.listaCheckups.some(checkup => checkup.DataAnamnese !== null);

        if (!this.fezCheckup) {
          this.toastr.warning('Clique em Mudar Perfil e realize seu CHECK-UP SAFE® para ter acesso ao seu link de cadastro.', 'Atenção', {
            timeOut: 10000,
            positionClass: 'toast-top-center'
          });
        }
      },
      err => {
        this.toastr.error('Erro ao buscar checkups do cliente');
      }
    );
  }

    //isMobile
    if (window.innerWidth <  926) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
 
   
    this.loadingListaPacientes = true
    this.professorService.getListaPacientesProfessor(this.usuarioLogado.ID).subscribe((response: any) => {
      this.listaPacientes = response
       this.buscaFiltrada = response
    }, (error) => {
    }, () => {
      this.loadingListaPacientes = false
    })

    this.loadingCupom = true
    this.professorService.getCupomProfessor().subscribe((response: any) => {
      this.cupom = response
     }, (error) => {
    }, () => {
      this.loadingCupom = false
    })

      // @ts-ignore
  if (window.innerWidth <  926 && this.store?.source?._value?.ui?.menuSidebarCollapsed == false) {
    this.store.dispatch(new ToggleSidebarMenu());
  }
  }

  startTour() {
    this.guideTour.defaultStepOptions = tour.defaultStepOptions;
    this.guideTour.modal = true;
    this.guideTour.confirmCancel = false;
            //@ts-ignore
    this.guideTour.addSteps(tour.steps);
    this.guideTour.start();
      if (this.menuService.menuState.value == false) {
        this.menuService.toggleMenu(); 
      }
    }

  // gerar meu link avaliador
  gerarLinkAvaliador() {
    const nome = this.ajustarNome(this.appService.user?.Nome);
    const base64 = btoa("" + this.appService?.user?.ID);
    this.urlSafe =  window.location.origin;
      this.meuLink = `${this.urlSafe}/cadastro/${nome}#!/${base64}`;
  }

  handleClickAbrirModalReenviarSenha() {
    this.appService.openModal(ModalReenviarSenhaComponent)
  }

  handleClickAbrirModalSoliciarCheckup() {
    this.appService.openModal(ModalSoliciarCheckupComponent)
  }

  handleClickCheckupMente() {
    this.router.navigate(['/dashboard/acesso-avaliador/anamneses'])
  }

  handleClickCheckupFisico() {
    this.router.navigate(['/dashboard/acesso-avaliador/usuarios-avaliacoes'])
  }

  buscarPacientesPorFiltro() {
    if (this.filtroBuscaInput.length <= 0) {
      this.buscaFiltrada = this.listaPacientes
      return
    }

    this.buscaFiltrada = this.listaPacientes.filter(paciente => paciente?.Cliente?.toLowerCase().includes(this.filtroBuscaInput.toLowerCase()))
  }

  copiarCupom( cupom: string) {
     
    //copia o cupom para a area de transferencia 
    const el = document.createElement('textarea');
    el.value = cupom;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    this.toastr.success("Sucesso", "Seu cupom foi copiado!")


  }
  copiarLink(cod: string) {
    //copia o link para a area de transferencia 
    const el = document.createElement('textarea');
    el.value =  `${this.urlSafe}/cupom/promocao/${cod}`;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el)
    this.toastr.success("Sucesso", "Seu link foi copiado!")
  }

  toggleCheckboxBaixarLaudo(){
    this.laudoSemDados = !this.laudoSemDados
  }
 
  baixarLaudo(id: number) {
    if (!id) return
    if (this.laudoSemDados) {
      window.open(`https://api.plataformasafe.com.br/home/DownloadLaudoSemDadosPessoais/${id}`, "_blank")
      return
    }
    window.open(`https://api.plataformasafe.com.br/home/DownloadLaudoCompleto/${id}`, "_blank")
  }

  handleLaudoIAPacienteID(id: number) {
    window.open(`https://api.plataformasafe.com.br/home/DownloadLaudoCompletoAnaliseAI/${id}`, "_blank")
  }

  ajustarNome(nome: string | any) {
    if (!nome) return nome
    const partes = nome.split(" ");
    return partes[0] + "-" + partes[partes.length - 1];
  }


}
